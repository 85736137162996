import * as React from 'react'

export const ContentEngagements = (props: any) =>
<svg viewBox="0 0 44 44" fill="none" width={props.svgwidth} height={props.svgwidth} xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_9_212)">
    <rect width="34" height="34" transform="translate(5 6)" fill="white"/>
    <path d="M38.2272 6.77271H5.77271V39.2272H38.2272V6.77271Z" stroke="#1E4E69" stroke-width="2"
          stroke-miterlimit="10"/>
    <path d="M5.77271 11.4092H38.2272" stroke="#1E4E69" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M8.09094 9.09082H9.6364" stroke="#1E4E69" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M11.1818 9.09082H12.7272" stroke="#1E4E69" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M34 15H10V22H34V15Z" fill="#72CAC2" fill-opacity="0.5" stroke="#1E4E69" stroke-width="2"
          stroke-miterlimit="10"/>
    <path d="M9 27L35 27" stroke="#FBE57F" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M8.86365 31.5H35.1364" stroke="#FBE57F" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M8.86365 35.3635H35.1364" stroke="#FBE57F" stroke-width="2" stroke-miterlimit="10"/>
  </g>
</svg>

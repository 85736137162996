import * as React from 'react'
import './new-upcoming-schedule.css'

export const NewUpcomingSchedule = (props: any) =>
<svg viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" width={props.svgwidth} height={props.svgheight}>
<path d="M38.2273 6.77271H5.77272V39.2272H38.2273V6.77271Z" stroke="#1E4E69" stroke-width="2" stroke-miterlimit="10"/>
<path d="M5.77272 11.4092H38.2273" stroke="#1E4E69" stroke-width="2" stroke-miterlimit="10"/>
<path d="M8.09091 9.09082H9.63637" stroke="#1E4E69" stroke-width="2" stroke-miterlimit="10"/>
<path d="M11.1818 9.09082H12.7273" stroke="#1E4E69" stroke-width="2" stroke-miterlimit="10"/>
<path d="M22 15.2727H9.63637V26.8636H22V15.2727Z" fill="#72CAC2" fill-opacity="0.5" stroke="#1E4E69" stroke-width="2" stroke-miterlimit="10"/>
<path d="M25.0909 15.2727H35.1364" stroke="#FBE57F" stroke-width="2" stroke-miterlimit="10"/>
<path d="M25.0909 19.1365H35.1364" stroke="#FBE57F" stroke-width="2" stroke-miterlimit="10"/>
<path d="M25.0909 23H35.1364" stroke="#FBE57F" stroke-width="2" stroke-miterlimit="10"/>
<path d="M25.0909 26.8635H35.1364" stroke="#FBE57F" stroke-width="2" stroke-miterlimit="10"/>
<path d="M8.86363 31.5H35.1364" stroke="#FBE57F" stroke-width="2" stroke-miterlimit="10"/>
<path d="M8.86363 35.3635H35.1364" stroke="#FBE57F" stroke-width="2" stroke-miterlimit="10"/>
</svg>